import React from 'react';
import { InternationalisedRoutes } from './i18n-utils';
import Link from 'components/Link';
import { domToReact, attributesToProps } from 'html-react-parser';
import internationalisedRoutes from '../routes/index.js';
import { locale } from 'typings/shared';
import Script from 'next/script';

const getInternalUrl = (path: string) => {
  const splittedPath = path.split('?')[0].split('/').filter(Boolean);
  let computedPath = path;
  if (splittedPath.length > 1 && (Object.values(locale) as Array<string>).includes(splittedPath[0])) {
    computedPath = splittedPath.slice(1).join('/');
  }
  const hit = (internationalisedRoutes as InternationalisedRoutes[]).find((route) => computedPath === route.localName);

  if (hit) return hit.pathName;
  return path;
};

export const isUrlInternal = (path: string) => {
  const splittedPath = path.split('?')[0].split('/').filter(Boolean);
  let computedPath = path;
  if (splittedPath.length > 1 && (Object.values(locale) as Array<string>).includes(splittedPath[0])) {
    computedPath = splittedPath.slice(1).join('/');
  }
  const hit = (internationalisedRoutes as InternationalisedRoutes[]).find((route) => computedPath === route.localName);

  if (
    !computedPath ||
    computedPath.indexOf(`https:`) === 0 ||
    computedPath.indexOf(`#`) === 0 ||
    computedPath.indexOf(`http`) === 0 ||
    computedPath.indexOf(`://`) === 0 ||
    !hit
  ) {
    return false;
  }
  return true;
};

export const replace = (node: any) => {
  const attribs = node.attribs || {};
  if (node.name === `a`) {
    const { href, ...props } = attribs;
    if (props.class) {
      props.className = props.class;
      delete props.class;
    }

    if (props.onclick) {
      const onClick = props.onclick.split('return')[0];
      props.onClick = onClick;
      delete props.onclick;
    }

    if (href && isUrlInternal(href)) {
      const splittedPath = href.split('?')[0].split('/').filter(Boolean);
      const lang =
        splittedPath.length > 1 && (Object.values(locale) as Array<string>).includes(splittedPath[0])
          ? splittedPath[0]
          : '';
      const query = Object.fromEntries(new URLSearchParams(href.split('?')[1]));
      const computedHref = getInternalUrl(href);

      return (
        <Link
          route={computedHref}
          lang={lang}
          onClick={() => (props.onClick ? eval(props.onClick) : {})}
          query={query}
          {...props}
        >
          <a {...attributesToProps(props)} onClick={() => (props.onClick ? eval(props.onClick) : {})}>
            {!!node.children && !!node.children.length && domToReact(node.children, { replace })}
          </a>
        </Link>
      );
    } else {
      return (
        <a href={href} {...attributesToProps(props)} onClick={() => (props.onClick ? eval(props.onClick) : {})}>
          {!!node.children && !!node.children.length && domToReact(node.children, { replace })}
        </a>
      );
    }
  }

  if (node.name === `script`) {
    let content = node?.children[0]?.data || '';
    if (content && content.trim().indexOf(`WebFont.load(`) === 0) {
      content = `setTimeout(function(){${content}}, 100)`;
      return <script {...attribs} dangerouslySetInnerHTML={{ __html: content }}></script>;
    } else {
      const isWebflow = attribs?.src?.includes('/js/webflow.');
      if (isWebflow) {
        return (
          <Script
            {...attribs}
            onReady={() => {
              window && (window as any).Webflow && (window as any).Webflow.ready();
            }}
          >
            {content}
          </Script>
        );
      } else {
        // return <Script {...attribs}>{content}</Script>;
        return <script {...attribs} dangerouslySetInnerHTML={{ __html: content }}></script>;
      }
    }
  }
};

export const extractSitemapPaths = (sitemap: string = '') => {
  const webflowUrl = process.env.NEXT_PUBLIC_WEBFLOW_URL || '';
  const splitLocTag = /<loc>([\s\S]*?)<\/loc>/; // extracts string between <loc> and </loc>
  return sitemap
    .split(splitLocTag)
    .filter((substr) => substr.includes(webflowUrl))
    .map((url) => url.replace(webflowUrl, '').split('/').filter(Boolean))
    .filter((slugs) => slugs.length);
};
