import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React from 'react';
import { replace } from 'utils/webflow';
import parse from 'html-react-parser';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getPracticeAreas } from './api/practice-areas';
interface HomepageProps {
  bodyContent: string;
  headContent: string;
  hasHeader: boolean;
}

const IndexPage: React.FC<HomepageProps> = ({ bodyContent, headContent, hasHeader }) => {
  const parseOptions = { replace };

  return (
    <>
      <Head>{headContent && parse(headContent, parseOptions)}</Head>
      <main style={{ paddingTop: !hasHeader ? '77px' : 0 }}>{bodyContent && parse(bodyContent, parseOptions)}</main>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const cheerio = await import(`cheerio`);
  const axios = (await import(`axios`)).default;

  let lang = context.locale;
  let res;

  try {
    res = await axios(`${process.env.NEXT_PUBLIC_WEBFLOW_URL}/${lang}/home`);
  } catch (_) {
    try {
      res = await axios(`${process.env.NEXT_PUBLIC_WEBFLOW_URL}/de/home`);
    } catch (err: any) {
      throw new Error(err);
    }
  }

  if (!res) {
    throw new Error('No results');
  }

  const practiceAreasItems = await getPracticeAreas(context.locale || context.defaultLocale || 'de');

  const html = res.data;
  const $ = cheerio.load(html);
  const bodyContent = $('body').html();
  const headContent = $('head').html();
  const bodyClasses = $('body').attr('class');
  const hasFooter = !!$('footer').html();
  const hasHeader = !!$('#navbar').html();

  const props = {
    isWebflow: true,
    bodyContent,
    headContent,
    hasFooter,
    hasHeader,
    isHomepage: true,
    serverPAs: { practiceAreas: { items: practiceAreasItems } },
    ...(await serverSideTranslations(context.locale || context.defaultLocale || 'de', ['common'])),
  } as any;

  if (bodyClasses) props.bodyClasses = bodyClasses;

  return {
    props,
  };
};

export default IndexPage;
